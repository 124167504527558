import React, {useState} from 'react';
import Layout from "../components/layout"
import Loader from '../components/loading';
import SEO from "../components/seo";

import constants from '../helpers/constants';
import Fetch from '../helpers/fetch';
import { Container, Row, Col, Form, Button, Table, Alert } from "react-bootstrap"
import loaderGif from '../images/ajax-loader.gif'
import isStatusValidForBatchUpdate from '../helpers/validStatusForBatchUpdate'
import nextStatus from '../helpers/nextStatus'



const BatchStatusUpdate = () => {
  const [itemId, setItemId] = useState('');
  const [itemIdsState, setItemIdsState] = useState([])
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [failAlertMsg, setFailAlertMsg] = useState([])
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMsg, setSuccessAlertMsg] = useState('')
  const [showLoader, setShowLoader] = useState(false);

  const handleAddItemId = (e) => {
    if (!itemId) return;
    if (itemIdsState.some(item => Number(item.id) === Number(itemId))) return;
    const newItem = {
      id: itemId,
      exists: false,
      validStatus: false,
      inProgress: true,
      nextStatus: '',
    }
    const itemIdsStateCopy = [...itemIdsState];
    itemIdsStateCopy.push(newItem)
    setItemId('')
    processList(itemIdsStateCopy)
  }

  const processList =  async (itemIdsStateCopy) => {
    setShowLoader(true)
    const newItems = itemIdsStateCopy.filter(item=> !item.exists && !item.validStatus);
    for (let i = 0; i < newItems.length; i++) {
      await checkItemExists(itemIdsStateCopy, newItems[i]);
    }
    setShowLoader(false)
  }

  const checkItemExists = (itemIdsStateCopy, item) => {
    const url=`${constants.BACKEND_URL}/order-items/${item.id}`
    const index = itemIdsStateCopy.findIndex((x) => Number(x.id) === Number(item.id))

    Fetch.get(url).then(res => {
      if (!res) {
        setShowFailAlert(true);
        setFailAlertMsg([`Item Id: ${item.id} doesn't exist`]);
        return;
      }
      if (res && Number(res.id) === Number(item.id)) {
        setShowFailAlert(false);
        if (res.status) {
          // Item have status but need to check if it is valid status based on productionType, current status, set type, item type
          const data = {
            productionType: res.productionType,
            status: res.status,
            setType: res.setType,
            itemType: res.itemType
          }
          itemIdsStateCopy[index].currentStatus = res.status;
          itemIdsStateCopy[index].remarks = res.remarks;
          if (isStatusValidForBatchUpdate(data)) {
            itemIdsStateCopy[index].validStatus = true;
            //Get next status
            itemIdsStateCopy[index].nextStatus = nextStatus(data)
            
            itemIdsStateCopy[index].updatedOn = res.updatedOn
            itemIdsStateCopy[index].productionType = res.productionType
            itemIdsStateCopy[index].itemInBy = res.itemInBy
            itemIdsStateCopy[index].status = nextStatus(data)
            itemIdsStateCopy[index].custid = res.order.custid
          }
        } 
        itemIdsStateCopy[index].exists = true;
        itemIdsStateCopy[index].inProgress = false;
        
        setItemIdsState(itemIdsStateCopy);
      } 
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddItemId()
  }

  const formatItemForHTTPRequest = (item) => {
    const {
      id, 
      updatedOn, 
      status,
      productionType,
      custid,
    } = item;
    
    const data = {
      id,
      updatedOn,
      status,
      productionType,
      sendSMS: false,
      custid,
    }
    return data;
  }

  const handleProcessAll = async (e) => {
    setShowFailAlert(false);
    setShowSuccessAlert(false);

    const allItemsValid = itemIdsState.every(item => {
      return item.validStatus && item.exists && item.nextStatus
    })
    if (!allItemsValid) {
      setShowFailAlert(true);
      setFailAlertMsg(['Please remove all items with warning sign to continue'])
      return;
    }

    setShowLoader(true)
    let failedIds = []
    for (let i = 0; i < itemIdsState.length; i++) {
      const element = itemIdsState[i];
      const data = formatItemForHTTPRequest(element)
      await Fetch.put(`${constants.BACKEND_URL}/order-items/prodsheet`, JSON.stringify(data)).then(res => {
        if (!res.status) {
          failedIds.push(`ID: ${data.id} ${res.msg ? res.msg: ''}`)
          return;
        }
        if (res.status) {
          if (res && res.id) {
            clearItemFromList(res.id)
          }
        }
        resetList();
      });
    }

    if (failedIds.length > 0 ) {
      setShowFailAlert(true);
      setFailAlertMsg(failedIds);
      setShowLoader(false)
      return;
    }
    setShowSuccessAlert(true);
    setSuccessAlertMsg('Items processed successfully')
    setShowLoader(false)

  }

  const clearItemFromList = (id) => {
    const index = itemIdsState.findIndex((item => Number(item.id) === Number(id)))
    if (index === -1) return;
    const itemIdsStateCopy = [...itemIdsState]
    itemIdsStateCopy.splice(index, 1);
    setItemIdsState(itemIdsStateCopy)
  }

  const resetList = () => {
    setItemIdsState([])
  }

  const handleOnDeleteRowClick = (e) => {
    const {index} = e.target.dataset;
    const itemIdsStateCopy = [...itemIdsState]
    itemIdsStateCopy.splice(index, 1);
    setItemIdsState(itemIdsStateCopy)

  }

  return (
    <Layout data-testid="hero-title" pageInfo={{ pageName: "inPro" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="InPro" />
      <Container>
        <Row>
          <Col className="text-center mb-2">
            <h5>InPro</h5>
            <h6><small>- Enter Item Ids to quickly update item to the next default status -</small></h6>
          </Col>
        </Row>
        <Alert variant="danger" show={showFailAlert} dismissible>
          {failAlertMsg && failAlertMsg.length > 0 ? 
            <ul>
                {failAlertMsg.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
            </ul> : ''         
          } 
        </Alert>
        <Alert variant="success" show={showSuccessAlert} dismissible>{successAlertMsg}</Alert>
        <Row>
          <Col>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} >
                <Form.Label column sm={2}>
                  Enter Item Id
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    data-property={''} 
                    type="text" 
                    placeholder={'Item Id'} 
                    value={itemId} 
                    onChange={(e) => setItemId(e.target.value)} 
                    />
                  <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
                </Col>
                <Col sm={2}>
                  <Button onClick={handleAddItemId}>Add Item ID</Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              itemIdsState &&  itemIdsState.length > 0 && (
                <>
                  <Table responsive>
                    <thead>
                      <tr className="text-center">
                        <th>Item ID</th>
                        <th>Item Status Valid</th>
                        <th>Current Status</th>
                        <th>Next Status</th>
                        <th>Remarks</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemIdsState && itemIdsState.length > 0 && itemIdsState.map((itemId, index) => {
                        return <tr value="" key={index} className="text-center">
                          <td>{itemId.id}</td>
                          <td>{itemId.validStatus ? 
                            <span className="oi oi-check text-success"></span> : 
                            <span className="oi oi-warning text-warning"></span>}
                          </td>
                          <td>
                            {itemId.currentStatus}
                          </td>
                          <td>
                            {itemId.nextStatus}
                          </td>
                          <td>
                            {itemId.remarks}
                          </td>
                          <td>
                            <span 
                              className="oi oi-circle-x pointer flex-fill"
                              title="Delete row"
                              data-index={index}
                              onClick={handleOnDeleteRowClick}
                            ></span>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                  <Button onClick={handleProcessAll}>Process All</Button>
                </>
              )
            }
          </Col>
        </Row>
        {/* {(<div><pre>{JSON.stringify(Array.from(itemIdsState), null, 2) }</pre></div>)} */}
      </Container>
    </Layout>
  )
}

export default BatchStatusUpdate