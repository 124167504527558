const isTrousers = (itemType) => {
  switch (itemType) {
    case 'LP':
    case 'PRIMO LP':
    case 'SP':
    case 'PRIMO SP':
      return true;
    default:
      return false;
  }
}

const isLongPants = (itemType) => {
  switch (itemType) {
    case 'LP':
    case 'PRIMO LP':
      return true;
    default:
      return false;
  }
}

const isShortPants = (itemType) => {
  switch (itemType) {
    case 'SP':
    case 'PRIMO SP':
      return true;
    default:
      return false;
  }
}

const isShirts = (itemType) => {
  switch (itemType) {
    case 'LS':
    case 'PRIMO LS':
    case 'SS':
    case 'PRIMO SS':
      return true;
    default:
      return false;
  }
}

const isLongSleeveShirts = (itemType) => {
  switch (itemType) {
    case 'LS':
    case 'PRIMO LS':
      return true;
    default:
      return false;
  }
}
const isShortSleeveShirts = (itemType) => {
  switch (itemType) {
    case 'SS':
    case 'PRIMO SS':
      return true;
    default:
      return false;
  }
}

const isJackets = (itemType) => {
  switch (itemType) {
    case 'SBJ':
    case 'PRIMO SBJ':
    case 'DBJ':
    case 'PRIMO DBJ':
      return true;
    default:
      return false;
  }
}

const isSBJ = (itemType) => {
  switch (itemType) {
    case 'SBJ':
    case 'PRIMO SBJ':
      return true;
    default:
      return false;
  }
}

const isDBJ = (itemType) => {
  switch (itemType) {
    case 'DBJ':
    case 'PRIMO DBJ':
      return true;
    default:
      return false;
  }
}

const isVests = (itemType) => {
  switch (itemType) {
    case 'SBV':
    case 'PRIMO SBV':
    case 'DBV':
    case 'PRIMO DBV':
      return true;
    default:
      return false;
  }
}

const isSBV = (itemType) => {
  switch (itemType) {
    case 'SBV':
    case 'PRIMO SBV':
      return true;
    default:
      return false;
  }
}

const isDBV = (itemType) => {
  switch (itemType) {
    case 'DBV':
    case 'PRIMO DBV':
      return true;
    default:
      return false;
  }
}

const isSkirt = (itemType) => {
  switch (itemType) {
    case 'SKIRT':
      return true;
    default:
      return false;
  }
}

export {isTrousers, isLongPants, isShortPants, isShirts, isLongSleeveShirts, isShortSleeveShirts, isJackets, isSBJ, isDBJ, isSkirt, isVests, isSBV, isDBV}
