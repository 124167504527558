const XFFlow = {
  A: {
    'FabricOrderedXF': {
      options: {
        'FabricReceivedXF': 'Fabric Received XF'
      }
    },
    'FabricReceivedXF': {
      options: {
        'ProcessingXF': 'Processing XF'
      }
    },
    'ProcessingXF': {
      options: {
        'QCFailXF1': 'QCFailXF1',
        'FittingXF': 'FittingXF' 
      },
      nextOption: 'FittingXF'
    }, 
    'QCFailXF1': {
      options:  {
        'QCFailXF1': 'QCFailXF1',
        'FittingXF': 'FittingXF',
      },
      nextOption: 'FittingXF'
    },
    'FittingXF': {
      options: {
        'AlteringXF': 'AlteringXF',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'AlteringXF': {
      options: {
        'QCFailXF2': 'QCFailXF2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    
    'QCFailXF2': {
      options:  {
        'QCFailXF2': 'QCFailXF2',
        'Collection': 'Collection'
      }
    },
    'Collection': {
      options: {
        'AlteringXF': 'AlteringXF',
        'Collected': 'Collected',
      }
    },
    'Collected': {
      options: {}
    }     
  },
  B: {
    'FabricOrderedXF': {
      options: {
        'FabricReceivedXF': 'Fabric Received XF'
      }
    },
    'FabricReceivedXF': {
      options: {
        'ProcessingXF': 'Processing XF'
      }
    },
    'ProcessingXF': {
      options: {
        'Collection': 'Collection' 
      },
      nextOption: 'Collection'
    }, 
    'AlteringXF': {
      options: {
        'QCFailXF2': 'QCFailXF2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'QCFailXF2': {
      options:  {
        'QCFailXF2': 'QCFailXF2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collection': {
      options: {
        'AlteringXF': 'AlteringXF',
        'Collected': 'Collected',
      }
    },
    'Collected': {
      options: {}
    }     
  }
  
}
  
export default XFFlow