import {isJackets, isVests, isShirts, isTrousers} from './itemTypeHelpers'
import JVFlow from '../helpers/ProcessFlow/JVFlow'
import SPFlow from '../helpers/ProcessFlow/SPFlow'
import AlterFlow from '../helpers/ProcessFlow/AlterFlow'
import BasteFitFlow from '../helpers/ProcessFlow/BFFlow'
import XFFlow from '../helpers/ProcessFlow/XFitFlow'
import FGFlow from '../helpers/ProcessFlow/FGFlow'

const nextStatus = ({productionType, setType, status, itemType}) => {
  let nextStatus ='';

  if (status === 'ProcessingFG' || status === 'AlteringFG') {
    nextStatus = FGFlow[status].nextOption
    return nextStatus
  }

  switch (productionType) {
    case 'normal':
      if(isJackets(itemType) || isVests(itemType)) {
        nextStatus = JVFlow[setType][status].nextOption;
      }
      if(isShirts(itemType) || isTrousers(itemType)) {
        nextStatus = SPFlow[setType][status].nextOption;
      }
      break;
    case 'xfit':
      if(isJackets(itemType) || isVests(itemType) || isTrousers(itemType) || isShirts(itemType)) {
        nextStatus = XFFlow[setType][status].nextOption;
      }
      break;
    case 'alteration':
      if(isJackets(itemType) || isVests(itemType) || isTrousers(itemType) || isShirts(itemType)) {
        nextStatus = AlterFlow[status].nextOption;
      }
      break;
    case 'bastefit':
      if(isJackets(itemType) || isVests(itemType) || isTrousers(itemType) || isShirts(itemType)) {
        nextStatus = BasteFitFlow[status].nextOption;
      }
      break;
    default:
      break;
  }
  return nextStatus;
}

export default nextStatus