const FGFlow = {
  'ProcessingFG': {
    options: {
      'QCFailFG': 'QCFailFG',
      'Collection': 'Collection' 
    },
    nextOption: 'Collection'
  }, 
  'QCFailFG': {
    options:  {
      'QCFailFG': 'QCFailFG',
      'Collection': 'Collection',
    }
  },
  'Collection': {
    options: {
      'AlteringFG': 'AlteringFG',
      'Collected': 'Collected'
    }
  },
  'AlteringFG': {
    options: {
      'QCFailFG': 'QCFailFG',
      'Collection': 'Collection'
    },
    nextOption: 'Collection'
  },
  'Collected': {
    options: {}
  }     
  
}
  
export default FGFlow