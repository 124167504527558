const JVFlow = {
  A: {
    'FabricOrderedJV': {
      options: {
        'FabricReceivedJV': 'Fabric Received JV'
      }
    },
    'FabricReceivedJV': {
      options: {
        'ProcessingJV': 'Processing JV'
      }
    },
    'ProcessingJV': {
      options: {
        'QCFailJV1': 'QCFailJV1',
        'FittingJV': 'FittingJV' 
      },
      nextOption: 'FittingJV'
    }, 
    'QCFailJV1': {
      options:  {
        'QCFailJV1': 'QCFailJV1',
        'FittingJV': 'FittingJV',
      },
      nextOption: 'FittingJV'
    },
    'FittingJV': {
      options: {
        'Refit': 'Refit',
        'ProcessingJV2': 'Processing JV 2'
      }
    },
    'Refit': {
      options: {
        'QCFailJV1': 'QCFailJV1',
        'FittingJV': 'FittingJV',
      },
      nextOption: 'FittingJV'
    },
    'ProcessingJV2': {
      options: {
        'QCFailJV2': 'QCFailJV2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'QCFailJV2': {
      options:  {
        'QCFailJV2': 'QCFailJV2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collection': {
      options: {
        'AlteringJV': 'AlteringJV',
        'Collected': 'Collected',
      }
    },
    'AlteringJV': {
      options: {
        'QCFailJV2': 'QCFailJV2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collected': {
      options: {}
    }     
  },
  B: {
    'FabricOrderedJV': {
      options: {
        'FabricReceivedJV': 'Fabric Received JV'
      }
    },
    'FabricReceivedJV': {
      options: {
        'ProcessingJV2': 'Processing JV2'
      }
    },
    // 'ProcessingJV': {
    //   options: {
    //     'ProcessingJV2': 'ProcessingJV2' 
    //   }
    // }, 
    'ProcessingJV2': {
      options: {
        'QCFailJV2': 'QCFailJV2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'QCFailJV2': {
      options:  {
        'QCFailJV2': 'QCFailJV2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collection': {
      options: {
        'AlteringJV': 'AlteringJV',
        'Collected': 'Collected',
      }
    },
    'AlteringJV': {
      options: {
        'QCFailJV2': 'QCFailJV2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collected': {
      options: {}
    }     
  }
}
  
export default JVFlow