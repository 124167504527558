const AlterFlow = {
  'ProcessingAT': {
    options: {
      'QCFailAT': 'QCFailAT',
      'FittingAT': 'FittingAT' 
    },
    nextOption: 'FittingAT'
  }, 
  'QCFailAT': {
    options:  {
      'QCFailAT': 'QCFailAT',
      'FittingAT': 'FittingAT',
    },
    nextOption: 'Collection'
  },
  'FittingAT': {
    options: {
      'AlteringAT': 'AlteringAT',
      'Collection': 'Collection' 
    },
    nextOption: 'Collection'
  },
  'AlteringAT': {
    options: {
      'QCFailAT': 'QCFailAT',
      'Collection': 'Collection'
    },
    nextOption: 'Collection'
  },
  'Collection': {
    options: {
      'AlteringAT': 'AlteringAT',
      'Collected': 'Collected'
    }
  },
  'Collected': {
    options: {}
  }     
  
}
  
export default AlterFlow