import {isJackets, isVests, isShirts, isTrousers} from './itemTypeHelpers'

const JVValidStatuses = {
  A: ['ProcessingJV', 'Refit', 'QCFailJV1', 'ProcessingJV2', 'AlteringJV','QCFailJV2', 'ProcessingFG', 'AlteringFG'],
  B: ['ProcessingJV2', 'AlteringJV','QCFailJV2', 'ProcessingFG', 'AlteringFG']
}
const SPValidStatuses = {
  A: ['ProcessingSP', 'QCFailSP1', 'FittingSP', 'AlteringSP','QCFailSP2', 'ProcessingFG', 'AlteringFG'],
  B: ['ProcessingSP', 'AlteringSP', 'QCFailSP2', 'ProcessingFG', 'AlteringFG']
}
const XFValidStatuses = {
  A: ['ProcessingXF', 'QCFailXF1', 'FittingXF', 'AlteringXF', 'ProcessingFG', 'AlteringFG'],
  B: ['ProcessingXF', 'AlteringXF', 'QCFailXF2', 'ProcessingFG', 'AlteringFG']
}
const ATValidStatuses = ['ProcessingAT', 'AlteringAT', 'QCFailAT']
const BFValidStatuses = ['ProcessingBF', 'RefitBF', 'FittingBF']

const isStatusValidForBatchUpdate = ({productionType, setType, status, itemType}) => {
  switch (productionType) {
    case 'normal':
      switch (setType) {
        case 'A':
          if (isJackets(itemType) || isVests(itemType)) {
            if (JVValidStatuses['A'].some(item => item === status))
            return true;
          }
          if (isShirts(itemType) || isTrousers(itemType)) {
            if (SPValidStatuses['A'].some(item => item === status))
            return true;
          }
          break;
        case 'B':
          if (isJackets(itemType) || isVests(itemType)) {
            if (JVValidStatuses['B'].some(item => item === status))
            return true;
          }
          if (isShirts(itemType) || isTrousers(itemType)) {
            if (SPValidStatuses['B'].some(item => item === status))
            return true;
          }
          break;
        default:
          break;
      }    
      break;
    case 'xfit':
      if(isJackets(itemType) || isVests(itemType) || isTrousers(itemType) || isShirts(itemType)) {
        switch (setType) {
          case 'A':
            if (XFValidStatuses['A'].some(item => item === status)) return true;
            break;
          case 'B':
            if (XFValidStatuses['B'].some(item => item === status)) return true;
            break;
          default:
            break;
        }
      }
      break;
    case 'alteration':
      if(isJackets(itemType) || isVests(itemType) || isTrousers(itemType) || isShirts(itemType)) {
        if (ATValidStatuses.some(item => item === status)) return true;
      }
      break;
    case 'bastefit':
      if(isJackets(itemType) || isVests(itemType) || isTrousers(itemType) || isShirts(itemType)) {
        if (BFValidStatuses.some(item => item === status)) return true;
      }
      break;
    default:
      break;
  }
  return false;
}

export default isStatusValidForBatchUpdate