const SPFlow = {
  A: {
    'FabricOrderedSP': {
      options: {
        'FabricReceivedSP': 'Fabric Received SP'
      }
    },
    'FabricReceivedSP': {
      options: {
        'ProcessingSP': 'Processing SP'
      }
    },
    'ProcessingSP': {
      options: {
        'QCFailSP1': 'QCFailSP1',
        'FittingSP': 'FittingSP' 
      },
      nextOption: 'FittingSP'
    }, 
    'QCFailSP1': {
      options:  {
        'QCFailSP1': 'QCFailSP1',
        'FittingSP': 'FittingSP',
      },
      nextOption: 'FittingSP'
    },
    'FittingSP': {
      options: {
        'AlteringSP': 'AlteringSP',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'AlteringSP': {
      options: {
        'QCFailSP2': 'QCFailSP2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'QCFailSP2': {
      options: {
        'QCFailSP2': 'QCFailSP2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collection': {
      options: {
        'AlteringSP': 'AlteringSP',
        'Collected': 'Collected',
      }
    },
    'ALTER': {
      options: {}
    },
    'Collected': {
      options: {}
    }     
  },
  B: {
    'FabricOrderedSP': {
      options: {
        'FabricReceivedSP': 'Fabric Received SP'
      }
    },
    'FabricReceivedSP': {
      options: {
        'ProcessingSP': 'Processing SP'
      }
    },
    'ProcessingSP': {
      options: {
        'Collection': 'Collection' 
      },
      nextOption: 'Collection'
    }, 
    'AlteringSP': {
      options: {
        'QCFailSP2': 'QCFailSP2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'QCFailSP2': {
      options: {
        'QCFailSP2': 'QCFailSP2',
        'Collection': 'Collection'
      },
      nextOption: 'Collection'
    },
    'Collection': {
      options: {
        'AlteringSP': 'AlteringSP',
        'Collected': 'Collected',
      }
    },
    'ALTER': {
      options: {}
    },
    'Collected': {
      options: {}
    }     
  }
}
  
export default SPFlow